import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./index.scss";
import * as Auth from "../../api/auth";
import { AuthContext } from "../../App";

const Login = ({ redirectTo, navigate }) => {
    const [auth, dispatch] = useContext(AuthContext);
    const [authError, setAuthError] = useState(null);

    useEffect(() => {
        if (auth.token) {
            navigate('/schedules');
        }
    }, [auth, navigate]);

    if (auth.token) {
        return 'Already logged in. Redirecting...';
    }

    const handleSubmit = async (
        { username, password },
        { setSubmitting}
    ) => {
        setAuthError(null);
        try {
            const res = await Auth.login(username, password);
            const { token, user } = res.data;
            dispatch({ type: "LOGIN", token, user });

            navigate("/schedules");
            setSubmitting(false);
        } catch (err) {
            setSubmitting(false);

            let errorLabel = '';
            switch (err.message) {
                case 'Network Error':
                    errorLabel = 'Serverforbindelse gået tabt. Kontakt Asger';
                    break;
                default:
                    errorLabel = 'Ukendt fejl opstod';
                    break;
            }

            if (err.response) {
                switch (err.response.status) {
                    case 500:
                        errorLabel = 'Der skete en serverfejl. Kontakt Asger';
                        break;
                    case 401: 
                        errorLabel = 'Forkert kombination';
                        break;
                    default:
                        errorLabel = 'Ukendt netværksfejl opstod';
                        break;
                }
            }
            
            setAuthError(errorLabel);
            console.log(err);
        }
    };

    return (
        <div className="login">
            <div className="login__main">
                <img src="/img/bb-logo.png" className="login__logo" alt="Logo"/>
                <h1>Log ind</h1>
                {authError ? (
                    <div className="alert alert-danger">
                        {authError}
                    </div>
                ) : ''}
                <Formik
                    initialValues={{ username: "", password: "" }}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="form-group">
                                <Field
                                    name="username"
                                    className="form-control"
                                    placeholder="Brugernavn"
                                    autoCorrect="off" 
                                    autoCapitalize="off"
                                />
                                <ErrorMessage name="username" component="div" />
                            </div>
                            <div className="form-group">
                                <Field
                                    type="password"
                                    name="password"
                                    className="form-control"
                                    placeholder="Kode"
                                />
                                <ErrorMessage name="password" component="div" />
                            </div>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-block btn-primary"
                            >
                                Log ind
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="login__void">
            </div>
        </div>
    );
};

export default Login;
