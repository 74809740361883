import React, { createContext, useReducer } from "react";
import AppRouter from "./AppRouter";
import jwtDecode from "jwt-decode";
import './app.scss';
import 'moment/locale/da';

export const AuthContext = createContext();
export const GamesContext = createContext();

const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                token: action.token,
                user: action.user
            };

        case "LOGOUT":
            return {
                ...state,
                token: null,
                user: null
            };

        default:
            return state;
    }
};

let initialAuth = {
    token: null,
    user: null
};

// Set user data if we have it saved
const token = localStorage.getItem("token");
if (token) {
    initialAuth.token = token;
    const { admin } = jwtDecode(token);
    initialAuth.user = {
        admin
    };
}

export default () => {
    return (
        <div className="app">
            <AuthContext.Provider value={useReducer(authReducer, initialAuth)}>
                <AppRouter />
            </AuthContext.Provider>
        </div>
    );
};
