import React, { useState, useEffect } from "react";
import "./index.scss";
import { protectedRoute } from "../../components/hoc/protectedRoute";
import * as SchedulesAPI from "../../api/schedules";
import ScheduleItem from "./ScheduleItem";
import Overlay from "../../components/Overlay";
import DummyItem from "../../components/DummyItem";

const Schedules = props => {
    const [loading, setLoading] = useState(true);
    const [schedules, setSchedules] = useState(null);
    const [error, setError] = useState(null);

    // Run this on mount
    useEffect(() => {
        SchedulesAPI.fetchSchedules()
            .then(schedules => {
                setSchedules(schedules);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err);

                // Do not post response if the request when unauthorized. Axios will logout automatically
                if (err.response && err.response.status === 401) {
                    return;
                }

                alert("Information om vagtplaner kunne ikke hentes.");
            });
    }, []);

    return (
        <div className="schedules">
            <div className="schedules__header">
                <h1>Vagtplaner</h1>
            </div>
            <div className="schedules__main">
                {schedules ? (
                    schedules.length ? (
                        schedules.map((schedule, index) => (
                            <ScheduleItem
                                {...{
                                    key: index,
                                    schedule,
                                    setLoading
                                }}
                            />
                        ))
                    ) : (
                        <p>Der kunne ikke findes nogen vagtplaner.</p>
                    )
                ) : error ? "Der opstod en fejl." : [1,2].map((_, index) => <DummyItem key={index} height={90} className="schedules__item" />)
                }
                <Overlay show={loading} title="Indlæser vagtplaner..." />

                {error ? error.toString() : ""}
            </div>
            <div>Overfør vagter til din kalender, eller se hvornår og med hvem du skal arbejde med.</div>
        </div>
    );
};

export default protectedRoute(Schedules);
