import React from "react";
import { time } from "./ShiftItem";

const DateShiftItem = ({ name, taker, start, end }) => {
    return (
        <div className="date-shift-item">
            <div className="date-shift-item__header">
                <h6>
                    {name}
                    <span style={{ fontWeight: 400 }}>
                        {start && end
                            ? ` - kl. ${time(start)}-${time(end)}`
                            : " - Ukendt start/slut"}
                    </span>
                </h6>
                <div>{taker.takerName}</div>
            </div>
        </div>
    );
};

export default DateShiftItem;
