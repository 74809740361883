import jwtDecode from "jwt-decode";
import { navigate } from "@reach/router";
import axios from "axios";

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const API = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
});

API.interceptors.request.use(config => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return config;
});

API.interceptors.response.use(res => res, async error => {
    if (!error.config.url.match('/auth/login') && error.response.status === 401) {
        // logout
        await navigate("/logout");
    }
    
    throw error;
});

export const retrieveUser = () => {
    const token = localStorage.getItem("token");

    // If no token was found return noting
    if (!token) return null;

    // Decode token, and extract user
    const { _id, email, name } = jwtDecode(token);

    return {
        _id,
        email,
        name
    };
};
