import React, { useEffect, useContext } from 'react'
import { AuthContext } from '../App';
import * as Auth from '../api/auth';

const Logout = props => {
    const [auth, dispatch] = useContext(AuthContext);

    if (auth.token) dispatch({ type: "LOGOUT" });
    Auth.logout();

    useEffect(() => {
        props.navigate('/');
    }, [props])

    return (
        <div>
            Logging out..
        </div>
    )
}

export default Logout
