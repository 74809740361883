import React, { useEffect, useState } from "react";
import "./index.scss";
import * as SchedulesAPI from "../../../api/schedules";
import Overlay from "../../../components/Overlay";
import ShiftItem from "./ShiftItem";
import DummyItem from "../../../components/DummyItem";

const Shifts = ({ url, takerName: taker }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [scheduleTitle, setScheduleTitle] = useState("vagtplanen");
    const [takerName, setTakerName] = useState(taker);
    const [shifts, setShifts] = useState(null);

    useEffect(() => {
        setError(null);

        const fetch = async () => {
            const { scheduleTitle, shifts, takerName } = await SchedulesAPI.fetchShifts(url, taker);
            setScheduleTitle(scheduleTitle);
            setTakerName(takerName);
            setShifts(shifts);
            setLoading(false);
        }
        
        fetch()
            .catch(err => {
                setLoading(false);
                setError(err);
                alert(
                    "Vagter for denne vagtplan eller person kunne ikke hentes."
                );
                console.log(err);
            });

    }, [url, takerName, setShifts, setScheduleTitle, taker]);

    return (
        <div className="schedules">
            <div className="schedules__header">
                <h1>Vagter</h1>
                <p>
                    For {takerName} på {scheduleTitle.toLowerCase()}
                </p>
            </div>
            <div className="schedules__main">
                {shifts ? (
                    shifts.length ? (
                        shifts.map((shift, index) => (
                            <ShiftItem shift={shift} takerName={takerName} url={url} key={index} />
                        ))
                    ) : (
                        <p>
                            Der blev ikke fundet nogle vagter på denne vagtplan.
                        </p>
                    )
                ) : (
                    <p>
                        {error ? "Der opstod en fejl." : [1,2,3,4,5,6,7,8,9,10].map((_, index) => <DummyItem key={index} className="shift-item"/>)}
                    </p>
                )}
                <Overlay show={loading} title="Indlæser vagter..." />
            </div>
        </div>
    );
};

export default Shifts;
