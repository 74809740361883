import React from "react";
import { time } from "./ShiftItem";

const DayScheduleItem = ({name, start, end}) => {
    return (
        <div className="day-schedule-item">
            <div className="day-schedule-item__header">
                <h6>{name} <span> - Kl. {time(start)}-{time(end)}</span></h6>
            </div>
        </div>
    );
};

export default DayScheduleItem;
