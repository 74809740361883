import { API } from '../api';
import FileSaver from 'file-saver';
import moment from 'moment';
import 'moment/locale/da';

export const downloadCalendar = async name => {
    const { data } = await API.get(`/calendar/all/${name}`, {
        responseType: 'blob',
    });

    return await FileSaver.saveAs(data, 'vagtplan.ics');
}

export const downloadCalendarForSchedule = async (url, name) => {
    const { data } = await API.get(`/calendar/${btoa(url)}/${name}`, {
        responseType: 'blob',
    });

    return await FileSaver.saveAs(data, 'vagtplan.ics');
}

export const fetchSchedules = async () => {
    const { data } = await API.get('/schedules');

    return data.map(entry => ({
        ...entry,
        published: moment(entry.published).locale('da'),
    }));
}

export const fetchShifts = async (url, name) => {
    const { data } = await API.get(`/schedules/${url}/shifts/${name}`);
    return data;
}

export const fetchShift = async (url, name, date) => {
    const { data } = await API.get(`/schedules/${url}/shift/${name}/${date}`);
    return data;
}