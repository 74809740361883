import { API } from '../api';

export const login = (username, password) => {
    return API.post("/auth/login", { username, password }, { handleNotAuthorized: false })
        .then(res => {
            localStorage.setItem('token', res.data.token);
            API.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
            return res;
        });
};

export const logout = () => {
    localStorage.removeItem('token');
    delete API.defaults.headers.common['Authorization'];
}
