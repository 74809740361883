import React, { useState, useEffect } from "react";
import Overlay from "../../../components/Overlay";
import * as SchedulesAPI from "../../../api/schedules";
import DateShiftItem from "./DateShiftItem";
import moment from "moment";
import { time } from "./ShiftItem";
import DummyItem from "../../../components/DummyItem";
import DayScheduleItem from "./DayScheduleItem";

const ShiftDetail = ({ url, takerName, date: dateString }) => {
    const [loading, setLoading] = useState(true);
    const [shift, setShift] = useState(null);
    const [blocks, setBlocks] = useState(null);
    const [scheduleTitle, setScheduleTitle] = useState(null);
    const [dateShifts, setDateShifts] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetch = async () => {
            const {
                dateShifts,
                shift,
                scheduleTitle
            } = await SchedulesAPI.fetchShift(url, takerName, dateString);

            // Prepare blocks
            setBlocks(shift.blocks ? shift.blocks.reduce((acc, block, index) => {
                if (acc.length === 0 || acc[acc.length - 1].name !== block.name) {
                    acc.push(block);
                    return acc;
                }
    
                acc[acc.length - 1].end = block.end;
                return acc;
            }, []) : null);

            setLoading(false);
            setDateShifts(dateShifts);
            setShift(shift);
            setScheduleTitle(scheduleTitle);
        };

        fetch().catch(err => {
            setLoading(false);
            setError(err);
            alert("Der kunne ikke hentes detaljeret data om vagten.");
            console.log(err);
        });
    }, [setShift, setScheduleTitle, setDateShifts, url, takerName, dateString]);

    const date = moment(atob(dateString)).local("da");

    return (
        <div className="schedules">
            <div className="schedules__header">
                <h1>
                    Vagt <small>{shift ? shift.name : "indlæser..."}</small>
                </h1>
                <div className="shift-detail__title">Dato & tid</div>
                <p>
                    {date.format("dddd Do MMMM YYYY")}{" "}<br/>
                    {shift && shift.start && shift.end ? (
                        <span>
                            kl. {time(shift.start)}-{time(shift.end)} (
                            {shift.duration} t)
                        </span>
                    ) : shift ? (
                        "- Ukendt start/slut"
                    ) : (
                        "Indlæser tid..."
                    )}
                </p>

                <div className="shift-detail__title">Vagtplan</div>
                {scheduleTitle ? <p>{scheduleTitle}</p> : <p>Indlæser...</p>}
            </div>
            <div className="schedules__main">
                <h4>Arbejdsplan <small>{!shift ? "Indlæser..." : "Fejl kan forekomme"}</small></h4>
                {shift && shift.blocks ? blocks.map((block, index) => (
                    <DayScheduleItem key={index} {...block} />
                )) : shift || error ? (
                    <p>Data om vagten ikke fundet<br/><br/></p>
                ) : [1,2,3].map((_, index) => <DummyItem key={index} className="day-schedule-item" />)}

                <h4>
                    Andre vagter <small>sorteret efter tid</small>
                </h4>
                {shift
                    ? dateShifts.map((dateShift, index) => (
                          <DateShiftItem {...dateShift} key={index} />
                      ))
                    : error
                    ? "Der opstod en fejl."
                    : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => <DummyItem key={index} className="date-shift-item" />)}
                <Overlay show={loading} title="Indlæser vagten..." />
            </div>
        </div>
    );
};

export default ShiftDetail;
