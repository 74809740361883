import React from 'react'
import { faSpinner} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Overlay = ({show, title}) => {
    return show ? (
            <div className="overlay">
                <FontAwesomeIcon icon={faSpinner} spin size="4x" />
                <p style={{marginTop: 30, fontWeight: 700}}>{title}</p>
            </div>
        ) : (
            ""
        )
    
}

export default Overlay
