import React, { Fragment } from "react";
import { Router } from "@reach/router"
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Schedules from "./pages/Schedules";
import Shifts from "./pages/Schedules/Shifts";
import ShiftDetail from "./pages/Schedules/Shifts/ShiftDetail";
import ScrollToTop from "./components/ScrollToTop";

const AppRouter = () => {
    return (
        <Router>
            <ScrollToTop path="/">
                <Login path="/" />
                <Fragment path="schedules">
                    <Schedules path="/" />
                    <Shifts path="/:url/shifts/:takerName" />
                    <ShiftDetail path="/:url/shift/:takerName/:date" />
                </Fragment>
                <Logout path="logout" />
            </ScrollToTop>
            
        </Router>
    );
};

export default AppRouter;
