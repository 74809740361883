import React, { useState } from "react";
import { Link } from "@reach/router";
import {
    faChevronDown,
    faCalendarPlus,
    faEye
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as SchedulesAPI from "../../api/schedules";
import Select from "react-select";

const ScheduleItem = ({ schedule, setLoading }) => {
    const [nameOption, setNameOption] = useState(null);
    const [focus, setFocus] = useState(false);
    const [active, setActive] = useState(false);

    const options = schedule.takerNames.map((name, index) => ({
        value: index,
        label: name
    }));

    const handleSubmit = event => {
        event.preventDefault();

        if (!nameOption) {
            alert('Udfyld venligst "Navn på vagtplan" korrekt.');
            return false;
        }

        setLoading(true);
        SchedulesAPI.downloadCalendarForSchedule(schedule.url, nameOption.label)
            .then(() => {
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);

                if (err.response.status === 404) {
                    return alert("Navnet blev ikke fundet på vagtplanen.");
                }

                alert("Der skete en fejl ved overførsel af kalenderen.");
                console.log(err);
            });
    };

    return (
        <div
            className={"schedules__item" + (active ? " open" : "")}
            onClick={() => setActive(!active)}
        >
            <div className="schedules__item__meta">
                <h5>{schedule.title}</h5>
                <p>
                    Udkom d. {schedule.published.format("Do MMMM YYYY")} kl.{" "}
                    {schedule.published.format("HH:mm")}
                </p>
            </div>
            <button className="btn schedules__item__collapse">
                <FontAwesomeIcon icon={faChevronDown} />
            </button>
            <div
                className="schedules__item__details"
                onClick={e => e.stopPropagation()}
            >
                <form onSubmit={handleSubmit}>
                    <Select
                        options={options}
                        name="name"
                        className="schedules__item__details__select"
                        placeholder="Søg efter navn..."
                        isClearable={true}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        blurInputOnSelect={true}
                        onChange={option => {
                            setNameOption(option);
                        }}
                        onInputChange={() => setFocus(false)}
                        value={focus ? null : nameOption}
                        styles={{
                            control: provided => ({
                                ...provided,
                                borderRadius: "2rem"
                            })
                        }}
                    />
                    <button type="submit" className="btn btn-block btn-light">
                        <FontAwesomeIcon icon={faCalendarPlus} />
                        Overfør til kalender
                    </button>
                </form>
                <Link
                    to={`/schedules/${btoa(schedule.url)}/shifts/${
                        nameOption ? nameOption.label : ""
                    }`}
                    className={
                        "btn btn-block btn-light" +
                        (!nameOption ? " disabled" : "")
                    }
                >
                    <FontAwesomeIcon icon={faEye} />
                    Se vagter
                </Link>
            </div>
        </div>
    );
};

export default ScheduleItem;
