import React from "react";
import moment from "moment";
import { Link } from '@reach/router';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./shift-item.css";

export const time = ([hours, minutes]) => {
    return `${hours}:${minutes === 0 ? "00" : minutes}`;
};

const ShiftItem = ({ shift, url, takerName }) => {
    const date = moment(shift.date).locale("da");

    return (
        <Link to={`/schedules/${url}/shift/${takerName}/${btoa(date.toISOString())}`} style={{textDecoration: 'none'}}>
            <div className="shift-item">
                <div className="shift-item__header">
                    <h6>
                        {shift.name}{" "}
                        <span style={{ fontWeight: 400 }}>
                            {shift.duration && shift.start
                                ? ` - (${shift.duration} t) kl. ${time(shift.start)}-${time(shift.end)}`
                                : " - (Ukendt periode)"}
                        </span>
                    </h6>
                    <div>
                        {date.format("dddd Do MMMM YYYY")}
                    </div>
                </div>
                <div
                    style={{ fontSize: 25, width: "100%", textAlign: "center" }}
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </div>
            </div>
        </Link>
    );
};

export default ShiftItem;
