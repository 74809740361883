import React from 'react';
import './dummy-item.scss';

const DummyItem = ({className, widths, height}) => {
    if (!widths) {
        widths = ['60%', '50%'];
    }

    const style = {};
    
    if (height) {
        style.height = height;
    }
    
    return (
        <div className={`${className} dummy-item`} style={style}>
            <div className={`${className}__header`}>
                <div style={{width: widths[0]}} className="dummy-item__title-loader"></div>
                <div style={{width: widths[1]}} className="dummy-item__sub-loader"></div>
            </div>
        </div>
    )
}

export default DummyItem
