import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../App';
import { navigate } from '@reach/router';

export const protectedRoute = Component => props => {
    const [auth] = useContext(AuthContext);
    const { token } = auth;

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token]);

    if (!token) {
        return <div>Redirecting...</div>;
    }

    return <Component {...props} />
}